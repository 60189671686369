import { View } from "react-native";
import useDevice from "src/hooks/useDevice";
import IconLogo from "src/icons/IconLogo";
import CreateAlbumButton from "src/screens/(informational)/components/CreateAlbumButton";
import SignInButton from "src/screens/(informational)/components/SignInButton";
import useNavBarSpacing from "src/screens/(informational)/hooks/useNavBarSpacing";
import { paddingXXL } from "src/styles/spacing";
import { Link } from "src/swsh-native";

const InformationalNavBar = () => {
	const {
		height: navBarHeight,
		paddingLeft: navBarLeft,
		paddingRight: navBarRight,
	} = useNavBarSpacing();
	const { device } = useDevice();

	return (
		<>
			<View
				style={{
					height: navBarHeight,
					width: "100%",
					backgroundColor: "transparent",
					alignItems: "center",
					zIndex: 1,
					flexDirection: "row",
					justifyContent: "space-between",
					paddingLeft: navBarLeft,
					paddingRight: navBarRight,
				}}
			>
				{device === "mobile" && <View style={{ flex: 1 }} />}
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: device === "desktop" ? "flex-start" : "center",
						flex: 1,
					}}
				>
					<Link href="/">
						<IconLogo size={48} />
					</Link>
				</View>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						columnGap: paddingXXL,
						flex: 1,
						justifyContent: "flex-end",
					}}
				>
					{device === "desktop" && <CreateAlbumButton />}
					<SignInButton />
				</View>
			</View>
		</>
	);
};
export default InformationalNavBar;
