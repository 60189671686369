import { useMemo } from "react";
import useDevice from "src/hooks/useDevice";
import useDim from "src/hooks/useDim";

const useFooterSpacing = (): {
	paddingLeft: number;
	paddingRight: number;
	height: number;
	isTwoRows: boolean;
} => {
	const { device } = useDevice();
	const { width } = useDim();
	const isTwoRows = useMemo(() => device === "mobile" || width < 1150, [device, width]);

	return {
		paddingLeft: width * 0.05,
		paddingRight: width * 0.05,
		height: 100,
		isTwoRows,
	};
};
export default useFooterSpacing;
