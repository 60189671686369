import { MDiv } from "src/swsh-native";
import { OpacityViewProps } from "./types";

const OpacityView = ({
	entering = true,
	exiting = true,
	duration = 300,
	style,
	children,
	pointerEvents,
	delay,
	testID,
	webLayoutId,
}: OpacityViewProps) => {
	return (
		<MDiv
			style={style}
			pointerEvents={pointerEvents}
			data-testid={testID}
			layoutId={webLayoutId}
			initial={
				entering
					? {
							opacity: 0,
						}
					: undefined
			}
			animate={{
				opacity: 1,
			}}
			exit={
				exiting
					? {
							opacity: 0,
						}
					: undefined
			}
			transition={{
				type: "tween",
				duration: duration / 1000,
				delay: delay ? delay / 1000 : undefined,
			}}
		>
			{children}
		</MDiv>
	);
};
export default OpacityView;
