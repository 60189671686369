"use client";

import { useMemo } from "react";
import { View } from "react-native";
import useStyle from "src/hooks/useStyle";
import IconInstagram from "src/icons/IconInstagram";
import IconLinkedIn from "src/icons/IconLinkedIn";
import IconTikTok from "src/icons/IconTikTok";
import IconTwitter from "src/icons/IconTwitter";
import { InformationalNavRoutes } from "src/screens/(informational)/constants";
import useFooterSpacing from "src/screens/(informational)/hooks/useFooterSpacing";
import { color } from "src/styles";
import { radiusSmall } from "src/styles/radius";
import { paddingMedium } from "src/styles/spacing";
import { Text } from "src/swsh-native";
import FooterIconButton from "./components/FooterIconButton";
import FooterRow from "./components/FooterRow";
import NavBarItem from "./components/NavBarItem.web";

const Footer = () => {
	const { theme } = useStyle();
	const { isTwoRows, paddingLeft, paddingRight } = useFooterSpacing();

	const numHalfNavRoutes = useMemo(() => Math.ceil(InformationalNavRoutes.length / 2), []);

	const FooterBorderStyles = useMemo(() => {
		return {
			borderColor: color.Divider[theme],
			borderWidth: 1,
			borderRadius: radiusSmall,
		};
	}, [theme]);

	return (
		<View
			testID="FooterContainer"
			style={[
				{
					width: "100%",
					flexDirection: "row",
					marginTop: isTwoRows ? 90 : paddingMedium,
					marginBottom: isTwoRows ? 28 : 56,
					paddingLeft,
					paddingRight,
					justifyContent: "center",
					alignItems: "center",
				},
			]}
		>
			<View
				style={
					isTwoRows
						? {
								flexDirection: "column",
								maxWidth: 337,
								rowGap: 20,
							}
						: {
								flexDirection: "row",
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
							}
				}
			>
				{!isTwoRows ? (
					// !isTwoRows => everything in one line
					<FooterRow style={FooterBorderStyles}>
						{InformationalNavRoutes.map((route, index) => (
							<NavBarItem
								href={route.href}
								key={route.href}
								target={route.target}
								isLast={index === InformationalNavRoutes.length - 1}
							>
								<Text style={{ fontSize: 16 }}>{route.title}</Text>
							</NavBarItem>
						))}
					</FooterRow>
				) : (
					// isTwoRows => half of items in each row and social icons underneath
					<View
						style={[
							{
								flexDirection: "column",
							},
							FooterBorderStyles,
						]}
					>
						<FooterRow
							style={{
								borderBottomWidth: FooterBorderStyles.borderWidth,
								borderBottomColor: FooterBorderStyles.borderColor,
							}}
						>
							{InformationalNavRoutes.slice(0, numHalfNavRoutes).map(
								(route, index) => (
									<NavBarItem
										href={route.href}
										key={route.href}
										target={route.target}
										isLast={index === numHalfNavRoutes - 1}
									>
										<Text style={{ fontSize: 11 }}>{route.title}</Text>
									</NavBarItem>
								),
							)}
						</FooterRow>
						<FooterRow>
							{InformationalNavRoutes.slice(numHalfNavRoutes).map((route, index) => (
								<NavBarItem
									href={route.href}
									key={route.href}
									target={route.target}
									isLast={index === numHalfNavRoutes - 1}
								>
									<Text style={{ fontSize: 11 }}>{route.title}</Text>
								</NavBarItem>
							))}
						</FooterRow>
					</View>
				)}
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						columnGap: 48,
						paddingHorizontal: 1, // looks more aligned
					}}
				>
					<FooterIconButton
						Icon={IconTikTok}
						url="https://www.tiktok.com/@swsh.sorority"
					/>
					<FooterIconButton
						Icon={IconInstagram}
						url="https://www.instagram.com/joinswsh/"
					/>
					<FooterIconButton
						Icon={IconLinkedIn}
						url="https://www.linkedin.com/company/joinswsh/"
					/>
					<FooterIconButton Icon={IconTwitter} url="https://twitter.com/joinswsh" />
				</View>
			</View>
		</View>
	);
};
export default Footer;
