import { ColorValue } from "react-native";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import OpacityView from "../OpacityView";
import NativeLoader from "./components/NativeLoader";
import { NativeLoaderProps } from "./components/NativeLoader/types";

const Loading = ({
	color: iconColor,
	variant = "Component",
	size = 20,
	...props
}: {
	color?: string | ColorValue;
	variant?: "Component" | "Screen";
} & NativeLoaderProps) => {
	const { theme } = useStyle();
	const LoadingComponent = (
		<NativeLoader color={iconColor ?? color.Text[theme]} size={size} {...props} />
	);
	if (variant === "Component") {
		return LoadingComponent;
	}
	return (
		<OpacityView
			entering={true}
			exiting={true}
			style={{
				flex: 1,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{LoadingComponent}
		</OpacityView>
	);
};
export default Loading;
