import Link from "next/link";
import useStyle from "src/hooks/useStyle";
import { IconComponent } from "src/icons";
import { color } from "src/styles";

const FooterIconButton = ({ Icon, url }: { Icon: IconComponent; url: string }) => {
	const { theme } = useStyle();

	return (
		<Link href={url} target="_blank">
			<Icon size={20} color={color.TextLight[theme]} />
		</Link>
	);
};
export default FooterIconButton;
