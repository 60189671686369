import type { Variants } from "framer-motion";
import { ReactNode } from "react";
import { Link } from "solito/link";
import useStyle from "src/hooks/useStyle";
import useFooterSpacing from "src/screens/(informational)/hooks/useFooterSpacing";
import { color } from "src/styles";
import { paddingMedium, paddingXXXL } from "src/styles/spacing";
import { MDiv } from "src/swsh-native";

const variants = {
	active: {
		transform: "scaleY(1.4)",
		opacity: 0.6,
	},
	default: {
		transform: "scaleY(1)",
		opacity: 0,
	},
} satisfies Variants;

const NavBarItem = ({
	href,
	children,
	target,
	isLast,
}: {
	children?: ReactNode;
	href: string;
	target?: "_blank" | "_self" | "_parent" | "_top";
	isLast?: boolean;
}) => {
	const { theme } = useStyle();
	const { isTwoRows } = useFooterSpacing();

	return (
		<Link href={href} target={target}>
			<MDiv
				initial="default"
				whileHover="active"
				style={[
					{
						paddingLeft: paddingXXXL,
						paddingRight: paddingXXXL,
						justifyContent: "center",
						borderColor: color.Divider[theme],
						paddingVertical: isTwoRows ? paddingMedium : 0,
					},
					!isLast && {
						borderRightStyle: "solid",
						borderRightWidth: 1,
						borderRightColor: color.Divider[theme],
					},
				]}
			>
				{children}
				<MDiv
					variants={variants}
					style={{
						position: "absolute",
						width: "80%",
						height: "100%",
						zIndex: 1,
						alignSelf: "center",
						borderTopStyle: "solid",
						borderBottomStyle: "solid",
						borderTopWidth: 1,
						borderBottomWidth: 1,
						borderColor: color.TextContrast[theme],
					}}
				/>
			</MDiv>
		</Link>
	);
};
export default NavBarItem;
