import useParseViewPropsToWeb from "src/hooks/useParseViewPropsToWeb";
import "./loader.css";
import { NativeLoaderProps } from "./types";

const NativeLoader = ({ style, color = "white", size = 20, testID }: NativeLoaderProps) => {
	const { style: parsedStyle = {} } = useParseViewPropsToWeb({
		style: [
			style,
			{
				width: size,
				// @ts-expect-error
				border: `${0.16 * size}px solid ${String(color)}`,
			},
		],
		pointerEvents: "auto",
	});

	return <div data-testid={testID} className="loader" style={parsedStyle}></div>;
};
export default NativeLoader;
