"use client";

import { ReactNode, useEffect, useState } from "react";

const ClientGuard = ({ children }: { children: ReactNode }) => {
	const [isReady, setIsReady] = useState(false);
	useEffect(() => {
		if (typeof window !== "undefined") {
			setIsReady(true);
		}
	}, []);
	if (!isReady) return null;
	return children;
};
export default ClientGuard;
