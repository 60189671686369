"use client";
import { ReactElement } from "react";
import SafeAreaProvider from "./SafeAreaProvider";
import ConfiguredGestureHandlerRootView from "./components/ConfiguredGestureHandlerRootView";

/**
 * Provides all the random providers that random packages require
 */
const PackageProvider = ({ children }: { children: ReactElement }) => {
	return (
		<ConfiguredGestureHandlerRootView>
			<SafeAreaProvider
				style={{
					flex: 1,
				}}
			>
				{children}
			</SafeAreaProvider>
		</ConfiguredGestureHandlerRootView>
	);
};

export default PackageProvider;
