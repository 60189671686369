import { ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import useFooterSpacing from "src/screens/(informational)/hooks/useFooterSpacing";
import { paddingMedium } from "src/styles/spacing";

const FooterRow = ({ children, style }: { children: ReactNode; style?: StyleProp<ViewStyle> }) => {
	const { isTwoRows } = useFooterSpacing();

	return (
		<View
			style={[
				{
					flexDirection: "row",
					maxWidth: "100%",
					alignItems: "center",
				},
				!isTwoRows && {
					paddingVertical: paddingMedium,
				},
				style,
			]}
		>
			{children}
		</View>
	);
};

export default FooterRow;
