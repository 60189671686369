"use client";

import { View } from "react-native";
import { MDiv } from "src/swsh-native";
import useInformationalStore from "../../stores/useInformationalStore";
import InformationalFooter from "./components/InformationalFooter/InformationalFooter.web";
import InformationalHeader from "./components/InformationalHeader/InformationalHeader.web";
import { InformationalContentWrapperProps } from "./types";

const InformationalContentWrapper = ({ children }: InformationalContentWrapperProps) => {
	const isFullScreenNav = useInformationalStore((state) => state.isFullScreenNav);

	return (
		<View
			style={{
				height: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<InformationalHeader />
			<MDiv
				style={{
					flex: 1,
				}}
				initial={{
					opacity: 1,
				}}
				animate={{
					opacity: isFullScreenNav ? 0 : 1,
				}}
			>
				{children}
			</MDiv>
			<MDiv
				initial={{
					opacity: 1,
				}}
				animate={{
					opacity: isFullScreenNav ? 0 : 1,
				}}
			>
				<InformationalFooter />
			</MDiv>
		</View>
	);
};
export default InformationalContentWrapper;
