import { createContext } from "react";
import { DefaultPalette } from "src/constants/DefaultPalette";

export interface PaletteContextType {
	palette: {
		primary: string;
		secondary: string;
		tertiary: string;
		isFallback: boolean;
	};
}
const PaletteContext = createContext<PaletteContextType>({
	palette: {
		...DefaultPalette,
		isFallback: true,
	},
});

export default PaletteContext;
