import type { ComponentType } from "react";
import type { ColorValue } from "react-native";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import type { SvgProps } from "react-native-svg";

export interface IconifyProps {
	/**
	 * w / h
	 */
	aspectRatio?: number;

	sizeBasedOn?: "width" | "height";
	testID: string;
}

export interface IconProps {
	color?: ColorValue;
	size?: number;
	style?: StyleProp<ViewStyle>;
}

const getWidthHeight = (
	size: number,
	{
		aspectRatio = 1,
		sizeBasedOn = "width",
	}: {
		aspectRatio?: number;
		sizeBasedOn?: "width" | "height";
	},
) => {
	if (sizeBasedOn === "width") {
		return {
			width: size,
			height: size / aspectRatio,
		};
	} else {
		return {
			width: size * aspectRatio,
			height: size,
		};
	}
};

const Iconify = (Icon: React.FC<SvgProps>, { testID, aspectRatio, sizeBasedOn }: IconifyProps) => {
	const NewIcon = ({ size = 32, color = "white", style, ...props }: IconProps) => {
		const dimensions = getWidthHeight(size, {
			aspectRatio,
			sizeBasedOn,
		});
		return (
			// Web icons have really weird sizing behavior, so we have to wrap them
			<View
				style={[
					{
						justifyContent: "center",
						alignItems: "center",
					},
					Platform.OS === "web" && dimensions,
					style,
				]}
			>
				<Icon data-testid={testID} color={color} {...props} {...dimensions} />
			</View>
		);
	};
	return NewIcon;
};
export type IconComponent =
	| ReturnType<typeof Iconify>
	// For lazy loading
	| ComponentType<IconProps>;

export default Iconify;
