import { StyleProp, ViewStyle } from "react-native";
import RoundedButton from "src/components/RoundedButton";

const CreateAlbumButton = ({ style }: { style?: StyleProp<ViewStyle> }) => {
	return (
		<RoundedButton
			href="/create"
			containerStyle={style}
			colorVariant="Primary"
			sizeVariant="Skinny"
		>
			Create Album
		</RoundedButton>
	);
};
export default CreateAlbumButton;
