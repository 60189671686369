import { create } from "zustand";

export interface InformationalStore {
	isFullScreenNav: boolean;
	toggleFullScreenNav: () => void;
	setFullScreenNav: (value: boolean) => void;
}

const useInformationalStore = create<InformationalStore>((set, get) => ({
	isFullScreenNav: false,
	toggleFullScreenNav: () => set((state) => ({ isFullScreenNav: !state.isFullScreenNav })),
	setFullScreenNav: (value) => set(() => ({ isFullScreenNav: value })),
}));
export default useInformationalStore;
