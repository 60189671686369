import RoundedButton from "src/components/RoundedButton";
import useDevice from "src/hooks/useDevice";

type SupportedSizeVariant = "Mini" | "Skinny";
const SignInButton = ({ overrideSizeVariant }: { overrideSizeVariant?: SupportedSizeVariant }) => {
	const { device } = useDevice();
	return (
		<RoundedButton
			href="/login?callback=%2Fhome"
			colorVariant="Hollow"
			target="_self"
			sizeVariant={
				overrideSizeVariant ??
				((device === "mobile" ? "Mini" : "Skinny") satisfies SupportedSizeVariant)
			}
		>
			Login
		</RoundedButton>
	);
};
export default SignInButton;
