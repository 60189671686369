import { View } from "react-native";
import Text from "../Text/Text";
import NativeLink from "./components/NativeLink";
import { LinkProps } from "./types";

const Link = ({ children, textProps, viewOnly, ...linkProps }: LinkProps) => {
	const Content =
		typeof children === "string" ? (
			<Text {...textProps} style={[{ textDecorationLine: "underline" }, textProps?.style]}>
				{children}
			</Text>
		) : (
			children
		);

	if (viewOnly) {
		return (
			<View style={linkProps.style} testID={linkProps.testID}>
				{Content}
			</View>
		);
	}
	return <NativeLink {...linkProps}>{Content}</NativeLink>;
};
export default Link;
